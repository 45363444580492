document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	hideElement();
	swiperInit();
	setBackground();
	counterAnimate();
	generalFnc();
	paginationFilter();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1024.1) {
		mappingFunc();
		hideElement();
	}
});
window.addEventListener("scroll", function () {
	const nav = document.querySelector(".back-to-top");
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		nav.classList.add("show");
	} else {
		nav.classList.remove("show");
	}
	if ($(window).width() > 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$("header").addClass("minimize");
		} else {
			$("header").removeClass("minimize");
		}
	}
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function paginationFilter() {
	$(".news-pagination-wrap").each(function (index) {
		let object = $(this).find(".document-item").length;
		if (object > 9) {
			var items = $(this).find(".document-item");
			var numItems = items.length;
			var perPage = 9;
			items.slice(perPage).hide();
			$(this)
				.find(".pagination-wrap")
				.attr("id", "pagination-container-" + index);
			$("#pagination-container-" + index).pagination({
				items: numItems,
				itemsOnPage: perPage,
				displayedPages: 3,
				onPageClick: function (pageNumber) {
					var showFrom = perPage * (pageNumber - 1);
					var showTo = showFrom + perPage;
					items.hide().slice(showFrom, showTo).show();
				},
			});
		}
	});
}

function generalFnc() {
	//Toggle Javascript code

	const bodyWrap = document.querySelector("body");
	const menuToggle = document.querySelector(".menu-toggle");

	const hamburger = document.querySelector(".hamburger");
	const menuMobile = document.querySelector(".mobile-nav-wrap");
	// const searchToggle = document.querySelector(".search-toggle");
	const searchToggle = document.querySelector(".search-toggle");
	const search = document.querySelector(".searchbox");

	//Back to top
	const backTop = document.querySelector(".back-to-top");

	// Menu function

	menuToggle.addEventListener("click", () => {
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});

	//Search Toggle

	searchToggle.addEventListener("click", function (event) {
		search.classList.toggle("open");
	});
	let clickCount = 0;
	$(".drop-down .title a").on("click", function (e) {
		e.preventDefault();
		clickCount++;

		if (clickCount === 1) {
			if ($(window).width() < 1200) {
				if ($(this).parents(".drop-down").hasClass("is-open")) {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
				} else {
					$(".drop-down .nav-sub").slideUp();
					$(".drop-down").removeClass("is-open");
					$(this).parent().next().slideDown();
					$(this).parents(".drop-down").addClass("is-open");
				}
			}
		} else if (clickCount === 2) {
			window.location.href = $(this).attr("href");
		}
	});

	if ($(window).width() < 1200) {
		$(".tertiary-menu-toggle").on("click", function () {
			$(".nav-tertiary-menu").slideToggle();
		});
	}

	document.addEventListener("click", (event) => {
		if (!event.target.closest(".search-toggle, .searchbox")) {
			search.classList.remove("open");
		}
		if (!event.target.closest(".mobile-nav-wrap, .menu-toggle")) {
			menuMobile.classList.remove("is-open");
			bodyWrap.classList.remove("overlay-bg");
			hamburger.classList.remove("is-active");
		}
	});
	//Side Nav  and back to top
	backTop.addEventListener("click", (event) => {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "300");
	});
	//Tab function

	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents(".tab-panel").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
	// Scroll to next
	$(".primary-banner .scroll-down").click(function () {
		let e = $(".home-about").offset().top - $("header").height();
		$("html, body").animate(
			{
				scrollTop: e,
			},
			1000
		);
	});
	//Toggle item
	const toggleItems = document.querySelectorAll(".toggle-item > .title");

	toggleItems.forEach((toggleItem) => {
		toggleItem.addEventListener("click", function (e) {
			e.preventDefault();
			const parent = this.parentNode;

			if (!parent.classList.contains("active")) {
				const articles = document.querySelectorAll(
					".toggle-item .article"
				);
				articles.forEach((article) => {
					article.style.display = "none";
				});

				const article = this.nextElementSibling;
				article.style.display = "block";

				const activeItems = document.querySelectorAll(
					".toggle-item.active"
				);
				activeItems.forEach((activeItem) => {
					activeItem.classList.remove("active");
				});

				parent.classList.add("active");
			} else {
				const article = this.nextElementSibling;
				article.style.display = "none";

				const activeItems = document.querySelectorAll(
					".toggle-item.active"
				);
				activeItems.forEach((activeItem) => {
					activeItem.classList.remove("active");
				});
			}
		});
	});
	$(".filter-display-wrapper").each(function () {
		let x = 3;
		$(this).find(".col").slice(0, 3).show();
		let size = $(this).find(".col").length;
		if (size < x || size == x) {
			$(this).find(".grid-center").addClass("disble-btn");
		}
		$(".filter-display-wrapper .btn-primary").on("click", function (e) {
			e.preventDefault();
			x = x + 6;
			$(this)
				.parents(".filter-display-wrapper")
				.find(".col")
				.slice(0, x)
				.slideDown();
			if (x >= size || size < x) {
				$(this).slideUp();
				$(this).find(".grid-center").addClass("disble-btn");
			}
		});
	});
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},

		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 20,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
}

// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
function counterAnimate() {
	const counterItem = document.querySelectorAll(".counter");
	if (counterItem.length) {
		const counterUp = window.counterUp.default;
		const callback = (entries) => {
			entries.forEach((entry) => {
				const el = entry.target;
				if (
					entry.isIntersecting &&
					!el.classList.contains("is-visible")
				) {
					counterUp(el, {
						duration: 2000,
						delay: 200,
					});
					el.classList.add("is-visible");
				}
			});
		};
		const IO = new IntersectionObserver(callback, { threshold: 1 });

		counterItem.forEach((counter) => {
			IO.observe(counter);
		});
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".searchbox",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrapper",
		desktopMethod: "appendTo",
		breakpoint: 1200.1,
	}).watch();
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1200.1,
	}).watch();
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [search, menu, language] = [
			".searchbox",
			".nav-primary-menu",
			".language-wrapper",
		].map((selector) => document.querySelector(selector));

		[search, menu, language].forEach((el) => (el.style.display = "flex"));
	}
}
